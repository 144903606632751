import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { $managementAxios, $axios } from "../../../lib/unility/http-service";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

class Rating extends Component {
	state = {
		bookingCode: "",
		options: [{ name: "Utility" }, { name: "Rating" }],
		booking: null,
		selectedDate: null,
		message: null,
		isAlreadyReviewed: false,
	};
	componentDidMount() {
		setInterval(() => {}, 30);
	}

	getBooking = () => {
		if (this.state.bookingCode) {
			this.getBookingByCode({ target: { value: this.state.bookingCode } });
		}
	};

	getBookingByCode = (e) => {
		//e.preventDefault();
		let code = e.target.value;
		this.setState({ bookingCode: code });
		$managementAxios.get("/booking/accommodations/code/" + code).then(
			(res) => {
				if (res && res.data) {
					let data = res.data;
					if (data.rating && data.rating > 0) {
						this.setState({
							isAlreadyReviewed: true,
							message: "Review already submited!",
						});
						toast.error("Review already submited!", {
							position: toast.POSITION.TOP_RIGHT,
						});
					}
					else {
						this.setState({
							isAlreadyReviewed: false,
							message: null,
						});
					}

					data["formattedCheckOutDate"] = moment(data.checkOutDate).format(
						"yyyy-MM-DD"
					);

					this.setState({ selectedDate: data["formattedCheckOutDate"] });
					this.setState({ booking: res.data });
				} else {
					this.setState({ booking: null, message: "Booking not found!" });
				}
			},
			(err) => {
				this.setState({ booking: null, message: "Booking not found!" });
			}
		);
	};

	handaledateChange = (e) => {
		this.setState({ selectedDate: e.target.value });
	}
	handleSubmit = (e) => {
		e.preventDefault();
		e.persist();

		const requestBody = JSON.stringify({
			booking_id: Number(e.target.booking_id.value),
			property_id: Number(e.target.property_id.value),
			rating: Number(e.target.rating.value),
			review_date: e.target.review_date.value,
			comment: e.target.comment.value,
			reviewer_id: e.target.reviewer_id.value,
		});

		$axios.post("/review", requestBody).then((res) => {
			if (res && res.data) {
				 toast.success("Review submited!", {
						position: toast.POSITION.TOP_RIGHT,
				});
			}
		},
		(err) => {
			toast.error("Failed to submit review!", {
				position: toast.POSITION.TOP_RIGHT,
			});
		});
	};

	copyToClipboard = (e) => {
		e.preventDefault();
		e.persist();
		const otp = this.state.oneTimePassword;
		navigator.clipboard.writeText(otp);
	};

	render() {
		return (
			<Fragment>
				<Breadcrumb options={this.state.options} />
				<div className='container-fluid'>
					<div className='card'>
						<div className='card-body'>
							<div className='row d-flex align-item-center justify-content-center'>
								<form className='form-inline'>
									<div className='form-group'>
										<label for='bookingCode' className='sr-only'>
											Booking Code
										</label>
										<input
											className='form-control'
											type='text'
											onChange={this.getBookingByCode}
											id='bookingCode'
											name='bookingCode'
											placeholder='Booking Code'
										/>
									</div>
									<button
										type='button'
										onClick={this.getBooking}
										className='btn btn-primary'
									>
										Get booking
									</button>
								</form>
							</div>
							{this.state.message && (
								<div className='alert alert-danger mt-2' role='alert'>
									{this.state.message}
								</div>
							)}
						</div>
					</div>
					{this.state.booking && (
						<div className='row mt-2'>
							<div className='card-group col-sm-12'>
								<div className='card'>
									<div className='card-body'>
										<table className='table'>
											<tbody>
												<tr>
													<td className='font-weight-bold'>Booking Code</td>
													<td>{this.state.booking.bookingCode}</td>
												</tr>
												<tr>
													<td className='font-weight-bold'>Check In</td>
													<td>{this.state.booking.checkInDate}</td>
												</tr>
												<tr>
													<td className='font-weight-bold'>Check Out</td>
													<td>{this.state.booking.checkOutDate}</td>
												</tr>
												<tr>
													<td className='font-weight-bold'>Booking Status</td>
													<td>{this.state.booking.bookingStatus}</td>
												</tr>
												<tr>
													<td className='font-weight-bold'>Guest</td>
													<td>
														{this.state.booking.guestAccount.firstName +
															" " +
															this.state.booking.guestAccount.lastName}
													</td>
												</tr>
												<tr>
													<td className='font-weight-bold'>Guest Phone</td>
													<td>{this.state.booking.guestAccount.phoneNumber}</td>
												</tr>
												<tr>
													<td className='font-weight-bold'>Host</td>
													<td>
														{this.state.booking.hostAccount.firstName +
															" " +
															this.state.booking.hostAccount.lastName}
													</td>
												</tr>
												<tr>
													<td className='font-weight-bold'>Property</td>
													<td>{this.state.booking.property.name}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<div className='card'>
									<div className='card-body'>
										<form onSubmit={this.handleSubmit}>
											<input
												type='hidden'
												name='booking_id'
												value={this.state.booking.id}
											/>
											<input
												type='hidden'
												name='property_id'
												value={this.state.booking.property.id}
											/>
											<input
												type='hidden'
												name='reviewer_id'
												value={this.state.booking.guestAccount.id}
											/>
											<div className='form-group'>
												<label htmlFor='review_date'>Date</label>
												<input
													type='date'
													className='form-control'
													id='review_date'
													name='review_date'
													value={this.state.selectedDate}
													onChange={this.handaledateChange}
													readOnly={this.state.isAlreadyReviewed}
												/>
											</div>
											<div className='form-group'>
												<label htmlFor='rating'>Rating</label>
												<input
													type='number'
													className='form-control'
													id='rating'
													name='rating'
													max={5}
													readOnly={this.state.isAlreadyReviewed}
												/>
											</div>
											<div className='form-group'>
												<label htmlFor='comment'>Comment</label>
												<textarea
													className='form-control'
													id='comment'
													name='comment'
													rows='3'
													readOnly={this.state.isAlreadyReviewed}
												></textarea>
											</div>
											<button
												type='submit'
												className='btn btn-primary'
												disabled={this.state.isAlreadyReviewed}
											>
												Submit
											</button>
											<ToastContainer />
										</form>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}

export default Rating;
