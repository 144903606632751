import {
	Home,
	Users,
	MessageSquare,
	Calendar,
	Globe,
	MapPin,
	Box,
	Menu,
} from "react-feather";

export const MENUITEMS = [
	{
		title: "Dashboard",
		path: "/dashboard",
		badgeType: "primary",
		icon: Home,
		type: "link",
		active: false,
	},
	{
		title: "User",
		icon: Users,
		type: "sub",
		active: false,
		children: [
			{
				path: "/applicationuser/applicationuserlist",
				title: "Application Users",
				type: "link",
			},
			{ path: "/adminuser/adminuserlist", title: "Admin Users", type: "link" },
			{ path: "/security/rolelist", title: "Role List", type: "link" },
		],
	},
	// {
	//     title: 'User', icon: Users, type: 'sub', active: false, children: [
	//         { path: '/applicationuser/applicationuserlist', title: 'Application User List', type: 'link' },
	//     ]
	// },
	{
		title: "Property",
		icon: Box,
		type: "sub",
		active: false,
		children: [
			{
				path: "/propertiesmanagement/propertylist",
				title: "Property List",
				type: "link",
			},
		],
	},
	{
		title: "Experience",
		icon: Globe,
		type: "sub",
		active: false,
		children: [
			{
				path: "/experiencemanagement/experiencelist",
				title: "Experience List",
				type: "link",
			},
		],
	},
	{
		title: "Advanture",
		icon: MapPin,
		type: "sub",
		active: false,
		children: [
			{
				path: "/adventuremanagement/adventurelist",
				title: "Adventure List",
				type: "link",
			},
		],
	},
	{
		title: "Reservation",
		icon: Calendar,
		type: "sub",
		active: false,
		children: [
			{
				path: "/reservation/adventure-list",
				title: "Adventure",
				type: "link",
			},
			{
				path: "/reservation/experience-list",
				title: "Experience",
				type: "link",
			},
			{
				path: "/reservation/properties-list",
				title: "Properties",
				type: "link",
			},
			{
				path: "/reservation/booking-request",
				title: "Booking Request",
				type: "link",
			},
		],
	},
	{
		title: "Messages",
		icon: MessageSquare,
		type: "sub",
		active: false,
		children: [
			{
				path: "/messages/message-list",
				title: "Messaging List",
				type: "link",
			},
		],
	},
	{
		title: "Utility",
		icon: Menu,
		type: "sub",
		active: false,
		children: [
			{
				path: "/utility/ganaret-otp",
				title: "Generate OTP",
				type: "link",
			},
			{
				path: "/utility/rating",
				title: "Rating",
				type: "link",
			}
		],
	},
];
