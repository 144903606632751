function JsonToQueryParams(json) {
	 return Object.entries(json)
			.filter(
				([_, value]) =>
					value !== null && value !== undefined && !Number.isNaN(value)
			)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			)
			.join("&");
}

export { JsonToQueryParams };
