import React, { useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import * as serviceWorker from "./serviceWorker";

// ** Import custom components for redux**
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./components/app";
import "rsuite/dist/rsuite.min.css";
import Login from "./pages/authentication/login";
import Error404 from "./pages/errors/error404";
import routes from "./app.route";

//config data
import configDB from "./data/customizer/config";


let loggedIn = localStorage.getItem("token");


function Root() {
	const abortController = new AbortController();

	useEffect(() => {
		const layout =
			localStorage.getItem("layout_version") ||
			configDB.data.color.layout_version;

		document.body.classList.add(layout);
		return function cleanup() {
			abortController.abort();
		};
	}, [abortController]);

	return (
		<div className='App'>
			<Provider store={store}>
				<BrowserRouter basename={`/`}>
					<ScrollContext>
						<Switch>
							<Route exact path='/'>
								{loggedIn ? <Redirect to='/dashboard' /> : <Login />}
							</Route>
							<Route
								path={`${process.env.PUBLIC_URL}/pages/authentication/login`}
								component={Login}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/pages/errors/error404`}
								component={Error404}
							/>
							<Fragment>
								<App>
									{routes.map((route, i) => (
										<RouteWithSubRoutes key={i} {...route} />
									))}
								</App>
							</Fragment>
						</Switch>
					</ScrollContext>
				</BrowserRouter>
			</Provider>
		</div>
	);
}

function RouteWithSubRoutes(route) {
	return (
		<Route
			path={route.path}
			render={(props) => (
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
}
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
