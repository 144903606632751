import React, { Component, Fragment } from "react";
import { $managementAxios } from "../../lib/unility/http-service";
import Breadcrumb from "../common/breadcrumb";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import Button from 'react-bootstrap/Button';
import BackButton from "../common/BackButton/BackButton";

class PropertiesReservationList extends Component {
  //id,hostname,guestname,property title,total amount, booking date action
  constructor(props){
    super(props);
    this.state={
      columns: [
				{
					name: "Id",
					selector: (row) => row.id,
					maxWidth: "10px",
					id: "id",
					sortable: true,
				},
				{
					name: "Host Name",
					selector: (row) => row.hostAccount?.firstName || "",
					sortable: true,
				},
				{
					name: "Guest Name",
					selector: (row) => row.guestAccount?.firstName || "",
					sortable: true,
				},
				{
					name: "Property Title",
					selector: (row) => row.property?.name || "",
					sortable: true,
				},
				{
					name: "Total Amount",
					selector: (row) => row.feesTotal ?? "",
					sortable: true,
				},
				{
					name: "Booking Date",
					selector: (row) => row.bookingDate,
					sortable: true,
				},
				{
					name: "Action",
					cell: (row) => (
						<div className='d-flex flex-row justify-content-center'>
							<div>
								<i
									className='btn icofont icofont-eye p-2'
									onClick={() => {
										this.viewPropDetails(row);
									}}
								></i>
							</div>
						</div>
					),
					button: true,
				},
			],
      loading:false,
      data:{},
      selectedId:null,
      parameters:{
        page: 1,
				pageSize: 10,
				sortBy: "id:desc",
      },
    }
  }

  showLoader() {
		this.setState({ loading: true });
	}

	closeLoader() {
		this.setState({ loading: false });
	}

	
	componentDidMount() {
		const storedParams = JSON.parse(localStorage.getItem("propertyParameters"));
		console.log("storedParameters",storedParams);
		if(storedParams){
			this.updateParameters(storedParams)
		}
		setTimeout(()=>{
			
			this.getData(
				this.state.parameters.page,
				this.state.parameters.pageSize,
				this.state.parameters.sortBy
				);
		},1000);
	
		
		
	  }

	updateParameters = (newParameters) => {
		this.setState({ parameters: newParameters }, () => {
		  localStorage.setItem("propertyParameters", JSON.stringify(newParameters));
		});
		console.log(this.state.parameters);
	  };

	getData = (pageNo, pageSize,sortBy) => {
		this.setState({ loading: true });
		$managementAxios
			.get(
				"/booking/accommodations?pageSize=" +
					pageSize +
					"&pageNo=" +
					pageNo +
					"&sortBy=" +
					sortBy
			)
			.then((response) => {
				response.data.content?.forEach((element) => {
					element.bookingDate = new moment(new Date(element.bookingDate)).format(
						"DD-MM-YYYY"
					);
					element.checkInDate = new moment(element.checkInDate).format(
						"DD-MM-YYYY"
					);
					element.checkOutDate = new moment(element.checkOutDate).format(
						"DD-MM-YYYY"
					);
				});
				this.setState({
					rowData: response.data.content,
					data: response.data,
					loading: false,
				});
			});
	};

	onChangePages = (page, totalRows) => {
		this.setState({ page: page });
		this.updateParameters({
			...this.state.parameters,
			page:page
		})
		  this.getData(
			page,
			this.state.parameters.pageSize,
			this.state.parameters.sortBy
		  );
	  };
	  
	  
	  onChangePerPage = (currentRowsPerPage, currentPage) => {
		this.setState({ pageSize: currentRowsPerPage})
		this.updateParameters({
			...this.state.parameters,
			pageSize:currentRowsPerPage
		})
		this.getData(
				this.state.parameters.page,
				currentRowsPerPage,
				this.state.parameters.sortBy
			);
	  };
	  

    viewPropDetails = (data) => {
		this.setState({redirect:"/reservation/propdetails" , selectedId:data.id,
		...this.state.parameters,
		});
	  };
		
	  search = () => {
		this.getData(
		  this.state.parameters.page,
		  this.state.parameters.pageSize,
		  this.state.parameters.sortBy
		);
	  };
	  

	  render() {
		if (this.state.redirect) {
		  return (
			<Redirect
			  to={{
				pathname: this.state.redirect,
				state: {
				  property_id: this.state.selectedId,
				  ...this.state.parameters,
				},
			  }}
			/>
		  );
		}
		return (
			<Fragment>
				<Breadcrumb options={this.state.breadcrumb} />
				<div className='container-fluid'>
					<div className='d-flex justify-content-between mb-3'>
						<div>
							<h4>Properties Reservation List</h4>
						</div>
						<div>
							<BackButton />
						</div>
					</div>
					<div className='d-flex flex-row justify-content-end my-2'>
						<div>
							<Button
								variant='primary'
								onClick={() => this.search()} // Call the search function on button click
							>
								Search
							</Button>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12'>
							<DataTable
								keyField='id'
								columns={this.state.columns}
								data={this.state.rowData}
								pagination={true}
								paginationServer={true}
								paginationPerPage={this.state.parameters.pageSize}
								paginationTotalRows={this.state.data.totalElements}
								onChangePage={this.onChangePages}
								onChangeRowsPerPage={this.onChangePerPage}
								progressPending={this.state.loading}
								paginationDefaultPage={this.state.parameters.page}
								dense
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	  }
}

export default PropertiesReservationList;
