import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import apiService from "../../apiservices/apiservice";
import { Redirect } from "react-router-dom";
import four from "../../assets/images/avtar/3.jpg";
import moment from "moment";
import BackButton from "../common/BackButton/BackButton";


class PropDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cities: [],
			country_city_id: "",
			loading: false,
			params:{
				page:this.props.location.state.page,
				pageSize:this.props.location.state.pageSize,
				bookingConfirmation:this.props.location.state.bookingConfirmation,
				sortBy:this.props.location.state.sortBy,
			},
			redirect: null,
			reservation_id: this.props.location.state
				? this.props.location.state.property_id
				: null,
			user_id: this.props.location.state
				? this.props.location.state.user_id
				: null,
			callFrom: this.props.location.state
				? this.props.location.state.callFrom
				: null,
			hostId: this.props.location.state
				? this.props.location.state.hostId
				: null,
			selectedData: this.props.location.state
				? this.props.location.state.selected_data
				: null,
			details: "",
			guest: "",
			experienceVO: "",
			//   experienceCalenderVO: "",
			//   currency: "",
			host: "",
			hostAccount: "",
			guestAccount: "",
			property: "",
			booking_date: "",
			check_indate: "",
			check_outdate: "",
			breadcurumbOptions: [
				{name: "Reservation Management",link: "/reservation/booking-request"},
				{name: "Booking Details"},
			],
		};
	}

	componentDidMount() {
		if (this.state.reservation_id > 0) {
			this.getDetails();
		}
	}

	getDetails() {
		let _this = this;
		let url = "booking/accommodations/" + this.state.reservation_id;
		apiService
			.get_mng_api(url)
			.then((response) => {
				_this.setState({
					details: response.data,
					guest: response.data.guest,
					property: response.data.property,
					host: response.data.host,
					guestAccount: response.data.guestAccount,
					hostAccount: response.data.hostAccount,
					booking_date:new moment(new Date(response.data.bookingDate)).format("DD-MM-YYYY"),
					check_indate: response.data.checkInDate,
					check_outdate: response.data.checkOutDate,
				});
			})
			.catch(function (error) {
				if (error.response) {
					if (error.response.status === 401) {
						_this.setState({ redirect: "/pages/authentication/login" });
					}
				}
			});
	}

	
	render() {


		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: this.state.redirect,
						...this.state.params
					}}
				/>
			);
		}



		

		const customCardStyle = {
			fontSize: "14px",
		};

		const profileDetailsStyle = {
			fontSize: "20px",
			marginBottom: "0",
		};

		const profileSubTitleStyle = {
			fontSize: "16px",
			marginTop: "5px",
		};

		const cardFooterTitleStyle = {
			fontSize: "10px",
			marginBottom: "0",
		};

		const cardProfileImgStyle = {
			width: "80px",
			height: "80px",
			objectFit: "cover",
			borderRadius: "50%",
			margin: "0 auto",
			marginBottom: "15px",
		};

		const phoneNumberStyle = {
			fontWeight: "bold",
			fontSize: "14px", // Adjust the font size as needed
		};

		return (
			<Fragment>
				 <div className='d-flex flex-column'>
   					 	<div><Breadcrumb options={this.state.breadcurumbOptions} /></div>
      					<div className='mb-2 ml-auto mr-4'>
						  <BackButton 
  							to='/reservation/booking-request'
						/>

    					</div>
  				</div>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-sm-6'>
							<div className='card custom-card' style={customCardStyle}>
								<div className='card-header'>
									<div className='row'>
										<div className='col-sm-12'>
											<h5>Guest & Booking Details</h5>
										</div>
									</div>
								</div>
								<div className='card-profile'>
									<img
										className='rounded-circle'
										src={this.state?.guestAccount?.profilePhotoPath}
										alt=''
										style={cardProfileImgStyle}
									/>
								</div>
								<div className='text-center profile-details'>
									<h4 style={profileDetailsStyle}>
										{this.state?.guestAccount?.firstName +
											" " +
											this.state?.guestAccount?.lastNamer}
									</h4>
									<h6 style={profileSubTitleStyle}>
										Number of Guest : {this.state?.details?.numberOfGuestsCount}
									</h6>
								</div>
								<div className='card-footer row'>
									<div className='col-4 col-sm-3'>
										<h6>Booking Id</h6>
										<h3 className='counter'>{this.state?.details?.id}</h3>
									</div>

									<div className='col-4 col-sm-3'>
										<h6>Total fees</h6>
										<h3>
											<span className='counter'>
												{this.state?.details?.feesTotal}
											</span>
										</h3>
									</div>
									<div className='col-4 col-sm-3'>
										<h6>Paid</h6>
										<h3>
											<span className='counter'>
												{this.state.details.bookingConfirmation === "CONFIRMED"
													? this.state.details.amountPaid
													: this.state.details.amountPaid}
											</span>
										</h3>
									</div>
									<div className='col-4 col-sm-3'>
										<h6>Phone Number</h6>
										<h5 style={phoneNumberStyle}>
											<span className='counter'>
												{this.state?.guestAccount?.phoneNumber}
											</span>
										</h5>
									</div>
								</div>
								<div className='card-body'>
									<div className='row card-header'>
										<h5>Property Details</h5>
									</div>
									<br />
									<div className='row'>
										<div className='col-sm-4 text-right'>
											<label>ID</label>
										</div>
										<div className='col-sm-8'>:{this.state?.property?.id}</div>
									</div>
									<div className='row'>
										<div className='col-sm-4 text-right'>
											<label>Title</label>
										</div>
										<div className='col-sm-8'>
											:{this.state?.property?.name}
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-4 text-right'>
											<label>Approval</label>
										</div>
										<div className='col-sm-8'>
											:{this.state?.property?.approvalFlag}
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-4 text-right'>
											<label>Price per guest</label>
										</div>
										<div className='col-sm-8'>
											:{this?.state?.property?.price}
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-4 text-right'>
											<label>Check in Date</label>
										</div>
										<div className='col-sm-8'>
											:{moment(this.state?.check_indate).format("DD-MM-yyyy")}
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-4 text-right'>
											<label>Check out Date</label>
										</div>
										<div className='col-sm-8'>
											:{moment(this.state?.check_outdate).format("DD-MM-yyyy")}
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-4 text-right'>
											<label>Booking Date</label>
										</div>
										<div className='col-sm-8'>
											:{this.state?.booking_date}
										</div>
									</div>
								</div>
							</div>
						</div>
						{this.state.hostAccount !== null && (
							<div className='col-sm-6'>
								<div className='card custom-card' style={customCardStyle}>
									<div className='card-header'>
										<div className='row'>
											<div className='col-sm-12'>
												<h5>Host Details</h5>
											</div>
										</div>
									</div>
									<div className='card-profile'>
										<img
											className='rounded-circle'
											src={
												this.state?.hostAccount?.profilePhotoPath
													? this.state?.hostAccount?.profilePhotoPath
													: four
											}
											alt=''
											style={cardProfileImgStyle}
										/>
									</div>
									<div
										className='text-center profile-details'
										style={profileDetailsStyle}
									>
										<h4>
											{this.state?.hostAccount?.firstName +
												" " +
												this.state?.hostAccount?.lastName}
										</h4>
										<h6>{this.state?.hostAccount?.bio}</h6>
									</div>
									<div className='card-footer row'>
										<div
											className='col-2 col-sm-2'
											style={cardFooterTitleStyle}
										>
											<h6>ID</h6>
											<h3 className='counter'>{this.state?.hostAccount?.id}</h3>
										</div>
										<div className='col-4 col-sm-4'>
											<h6>E-mail</h6>
											<h5>
												<span className='counter' style={{ fontSize: "12px" }}>
													{this.state?.hostAccount?.emailAddress}
												</span>
											</h5>
										</div>
										<div className='col-3 col-sm-3'>
											<h6>DOB</h6>
											<h3>
												<span className='counter' style={{ fontSize: "12px" }}>
													{moment(this.state?.hostAccount?.dateOfBirth).format(
														"DD-MM-yyyy"
													)}
												</span>
											</h3>
										</div>
										<div className='col-3 col-sm-3'>
											<h6>Phone Number</h6>
											<h5 style={phoneNumberStyle}>
												{this.state?.hostAccount?.phoneNumber}
											</h5>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					
				 
				</div>
			</Fragment>
		);
	}
}
export default PropDetails;
