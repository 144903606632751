
 function JsonToCsv(jsonData) {
	if (!jsonData || jsonData.length === 0) {
		return "";
	}
	const headers = Object.keys(jsonData[0]).join(",");
	const rows = jsonData.map((row) =>
		Object.values(row)
			.map((value) => `"${value}"`) 
			.join(",")
	); 
	return [headers, ...rows].join("\n");
}


function DownloadCsv(csvContent, fileName) {
	const blob = new Blob([csvContent], { type: "text/csv" });
	const url = URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.href = url;
	a.download = `${fileName}.csv`;
	a.click();

	URL.revokeObjectURL(url); 
}

function JsonToCsvFile(jsonData, fileName) {
    const csvContent = JsonToCsv(jsonData);
    DownloadCsv(csvContent, fileName);
}


export { JsonToCsv, JsonToCsvFile , DownloadCsv};